<script setup>
import informationTech from "~/assets/animation/globe-spin.json";
import solutions from '@/data/newsolution.json';

const { locale } = useI18n();
const localePath = useLocalePath();

const local = useLocal();
local.value = locale.value; // GLOBAL STATE'E EŞİTLEME

</script>

<template>
  <section data-aos="zoom-in" data-aos-once="true" data-aos-delay="100" data-aos-offset="200" data-aos-duration="400" class="py-12 space-y-20 px-4 md:px-8 2xl:px-0">
    <div class="max-w-7xl 3xl:max-w-8xl mx-auto relative flex justify-between">
      <div class="max-w-xl space-y-4 relative z-10">
        <h1 class="text-4xl font-bold text-gray-700 dark:text-gray-200 text-center md:text-left">
          {{ $t("hero-title") }}
        </h1>
        <p class="dark:text-gray-400 ">
          {{ $t("hero-content") }}
        </p>
        <div class="text-center md:text-left">
          <button
            class="pt-4 text-center md:text-left px-8 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50">
            <nuxt-link :to="localePath('/solutions/ERP-Solutions')">
              {{ $t("link-button") }}
            </nuxt-link>
          </button>
        </div>
      </div>
      <div
        class="max-w-xl absolute right-4 -top-14 opacity-20 dark:opacity-100 dark:brightness-150 transition duration-200">
        <client-only>
          <Vue3Lottie :animationData="informationTech" resizeMode="cover" />
        </client-only>
      </div>
    </div>

    <div
      class="max-w-7xl 3xl:max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 2xl:gap-20 bg-white dark:bg-gray-900 dark:border-gray-800 z-10 relative pt-12 pb-12 border-t border-b">
      <div v-for="item in solutions[local]" :key="item.title" class="flex items-start md:space-x-4 group hover:bg-gray-300 hover:scale-110 p-3 rounded-3xl duration-500 hover:dark:bg-gray-700 transform transition-all">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"
            class="w-12 h-12 text-gray-700 dark:group-hover:text-gray-200 transition duration-200" viewBox="0 0 24 24"
            fill="currentColor">
            <path :d="item.icon" />
          </svg>
        </div>
        <div>
          <NuxtLink :to="localePath(item.target)" :title="item.contentTitle">
            <h2 class="font-medium mb-1 text-gray-800 dark:text-gray-200">
              <span v-html="item.contentTitle"></span>
            </h2>
            <p class="text-sm text-gray-700 dark:text-gray-400 line-clamp-5">
              {{ item.contentDescriptionShort }}
            </p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>
